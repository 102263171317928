import { useState, useEffect, useRef } from 'react';
import * as ort from 'onnxruntime-web';
import './App.css';

const labelMappings = {
  capybara: { zh: "水豚", en: "Capybara" },
  diao: { zh: "貂", en: "Marten" },
  haibao: { zh: "海豹", en: "Seal" },
  haigou: { zh: "海狗", en: "Sea Dog" },
  hainiu: { zh: "海牛", en: "Manatee" },
  haishi: { zh: "海狮", en: "Sea Lion" },
  haita: { zh: "海獭", en: "Sea Otter" },
  haixiang: { zh: "海象", en: "Walrus" },
  heli: { zh: "河狸", en: "Beaver" },
  helishu: { zh: "河狸鼠", en: "Nutria" },
  meng: { zh: "獴", en: "Mongoose" },
  poster: { zh: "海报", en: "Poster" },
  rugen: { zh: "儒艮", en: "Dugong" },
  shuita: { zh: "水獭", en: "River Otter" },
  tuboshu: { zh: "土拨鼠", en: "Groundhog" }
};

const App = () => {
  const [session, setSession] = useState(null);
  const [prediction, setPrediction] = useState(null);
  const [language, setLanguage] = useState("zh");
  const [originalImage, setOriginalImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);

  const texts = {
    title: { zh: "这不是海獭 🦦", en: "This is not Sea Otter 🦦" },
    upload: { zh: "选择或拖放疑似海獭的照片", en: "Select or drop a photo seems Sea Otter" },
    onDevice: { zh: "浏览器本地运行，图片不会被上传", en: "Running locally in the browser, images will not be uploaded." },
    loading: { zh: "模型加载中...", en: "Loading model..." },
    modelError: { zh: "模型加载失败", en: "Failed to load model" },
    imageError: { zh: "图片处理失败", en: "Failed to process image" }
  };

  useEffect(() => {
    let progressInterval;
    const loadModel = async () => {
      try {
        progressInterval = setInterval(() => {
          setProgress((prev) => Math.min(prev + 5, 90));
        }, 100);

        const modelPath = "/model/not-sea-otter.onnx";
        const loadedSession = await ort.InferenceSession.create(modelPath);
        setSession(loadedSession);
        setProgress(100);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(texts.modelError[language]);
        setLoading(false);
      } finally {
        clearInterval(progressInterval);
      }
    };

    loadModel();
    return () => clearInterval(progressInterval);
  }, []);

  const handleImageUpload = (file) => {
    if (!file || !file.type.startsWith('image/')) {
      setError(texts.imageError[language]);
      return;
    }

    setError(null);
    setPrediction(null);
    const imageUrl = URL.createObjectURL(file);
    setOriginalImage(imageUrl);

    const img = new Image();
    img.src = imageUrl;
    img.onload = async () => {
      try {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, 224, 224);

        const imageData = ctx.getImageData(0, 0, 224, 224);
        const tensor = preprocessImage(imageData);
        const results = await session.run({ images: tensor });
        const output = results.output0;
        const maxIdx = output.data.indexOf(Math.max(...output.data));
        const predictedLabel = Object.keys(labelMappings)[maxIdx];
        const confidence = output.data[maxIdx];

        const confidenceText =
          confidence > 0.95
            ? language === "zh"
              ? "（确信）"
              : " (for sure)"
            : confidence > 0.5
            ? language === "zh"
              ? "(大概吧)"
              : " (perhaps)"
            : language === "zh"
            ? "我也不会了……"
            : " Maybe you got me...";

        setPrediction({
          label: predictedLabel,
          confidence: confidence,
          text:
            predictedLabel === "haita"
              ? language === "zh"
                ? `这是海獭！${confidenceText}`
                : `This is a Sea Otter! ${confidenceText}`
              : language === "zh"
              ? `这不是海獭，这是${labelMappings[predictedLabel][language]}！${confidenceText}`
              : `This is not a Sea Otter! It's a ${labelMappings[predictedLabel][language]}! ${confidenceText}`
        });
      } catch (err) {
        console.error(err);
        setError(texts.imageError[language]);
      } finally {
        URL.revokeObjectURL(imageUrl);
      }
    };

    img.onerror = () => {
      URL.revokeObjectURL(imageUrl);
      setError(texts.imageError[language]);
    };
  };

  const preprocessImage = (imageData) => {
    const { data, width, height } = imageData;
    const float32Data = new Float32Array(3 * width * height);

    for (let i = 0; i < width * height; i++) {
      float32Data[i] = data[i * 4] / 255.0;
      float32Data[i + width * height] = data[i * 4 + 1] / 255.0;
      float32Data[i + 2 * width * height] = data[i * 4 + 2] / 255.0;
    }

    return new ort.Tensor("float32", float32Data, [1, 3, width, height]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer?.files?.[0];
    if (file) handleImageUpload(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  return (
    <div className="container">
      <div className="header">
        <h1 className="title">{texts.title[language]}</h1>
        <button
          className="langButton"
          onClick={() => setLanguage((l) => (l === "zh" ? "en" : "zh"))}
        >
          {language === "zh" ? "EN" : "中"}
        </button>
      </div>

      {loading ? (
        <div className="loading">
          <div>{texts.loading[language]}</div>
          <div className="progress">
            <div className="progressBar" style={{ width: `${progress}%` }} />
          </div>
        </div>
      ) : (
        <>
          <div
            className={`uploadArea ${isDragging ? "uploadAreaActive" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={() => setIsDragging(false)}
            onClick={() => fileInputRef.current?.click()}
          >
            <div>{texts.upload[language]}</div>
            <div              style={{ fontSize: '0.5em', marginTop: '1em', color: '#8f8b87' }}
            >{texts.onDevice[language]}</div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e.target.files?.[0])}
              style={{ display: 'none' }}
            />
          </div>

          {error && <div className="error">{error}</div>}

          {originalImage && (
            <>
              <img src={originalImage} alt="Uploaded" className="image" />
              {prediction && (
                <div className="result">
                  <div>{prediction.text}</div>
                  <div className="progress">
                    <div
                      className="progressBar"
                      style={{ width: `${prediction.confidence * 100}%` }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
      <canvas ref={canvasRef} width="224" height="224" style={{ display: 'none' }} />
    </div>
  );
};

export default App;
